<template>
    <v-container max-width="150"
                 :class="`rounded ${disabled ? 'checkbox-disabled' : 'checkbox'} fill-height pt-0 pb-0 ma-0`"
                 :style="{ 'border': `1px solid ${ disabled ? '#bdbdbd' : (fieldChecked ? '#2196f3' : '#9e9e9e') }` }"
                 align="center">
        <v-row justify="space-between" align="center" class="ma-0">
            <v-col md="12" class="pa-0 vertical-center">
                <v-icon v-if="icon" class="mr-1">{{icon}}</v-icon>
                <span :class="`${(disabled ? 'disabled' : '')}`" style="padding: 8px 0px">{{label}}</span>
                <v-btn fab small elevation="0" :ripple="false" plain :disabled="disabled">
                    <v-icon :color="fieldChecked ? '#2196f3' : '#9e9e9e'">
                        {{fieldChecked ? 'mdi-check-circle' : 'mdi-circle-outline'}}
                    </v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: 'custom-checkbox',
        props: ['fieldChecked', 'label', 'disabled', 'icon'],
    }
</script>